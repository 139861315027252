@import url(https://fonts.googleapis.com/css2?family=Marko+One&family=Mukta&display=swap);

*{
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

.logo__text{
    text-align: justify;
    height: 80px;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}

.logo__text img{
    display: block;
    margin: 0 auto;
    width: 130px;
}

.logo__text p{
    padding: 0px;
}

/* .nav__logo{
    width: 180px;
    height: 50px;
} */

.navbar-toggler{
    border: 1px solid;
    background-color: #ccc;
}

.styles_scroll-to-top__2A70v{
    padding: 7px 7px;
    left: 40px !important;
    bottom: 40px !important;
}

.nav-link {
    color: #000000 !important;
    font-family: Roboto, Helvetica, Arial, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 700;
}


.dropdown-item{
    color: #000000;
    font-size: 16px;
}

.country__flag {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ccc;
    float: left;
    margin-right: 5px;
    margin-top: -10px;
}

.app{
    overflow: hidden;
}

.navbar__list {
    display: inline-grid;
    grid-template-columns: repeat(3, 3fr);
    grid-auto-rows: 70px;
    min-width: 44rem;
    list-style-type: none;
    padding: 10px;
}

.navbar__list li a{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.dropdown-menu.show{
    left: -21rem;
}

.mbbs__abroad p{
    display: block;
    padding-top: 7px;
    padding-right: 2px;
}

.gallery__grid{
    margin-top: 20px;
    margin-bottom: 20px;
}

.gallery__grid button{
    padding: 0px; 
}

@media(max-width: 768px) {
    .navbar__list {
        grid-template-columns: repeat(1, 1fr);
        min-width: 10rem;
    }
}

@media screen and (min-width: 968px) and (max-width: 1024px){

    .dropdown-menu.show{
        left: -22rem;
    }
}


p {
  display: inline-block;
  padding: 10px;
}

.topbar {
  display: flex;
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #ffffff;
  font-weight: 700;
  font-family: Roboto, Helvetica, Arial, Verdana, sans-serif;
}

.topbar__number a{
  color: #ffffff;
  text-decoration: none;
}

.topbar__number a:hover{
  color: #ffffff;
}

.social__container li{
  display: inline-flex;
  padding-left: 14px;
  padding-top: 10px;
  margin-right: 2px;
  color: #ffffff;
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.social__container li i{
  display: inline-flex;
  margin-right: 2px;
  color: #ffffff;
}

.social__container li:hover {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  color: #ffffff;
  transition: 0.4s;
}

.social__container li i:hover {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  color: #ffffff;
  transition: 0.4s;
  text-decoration: none;
}

@media (max-width:1024px) {
  .topbar{
    display: none;
  }
}
.footer{
    background-color: #003770;
    color: #ffffff;
    padding:25px;
}

.footer a{
    color: #ffffff;
}

.footer a:hover{
    /* color: #ffce01; */
    color: rgb(113,226,179);
    text-decoration: none;
    transition: 0.4s;
}

.footer h3{
    color: #ffffff;
    font-weight: 700;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.footer button{
    color: #ffffff;
    font-weight: 600;
}

.footer__links li{
    list-style-type: none;
    color: #ffffff;
}

.footer p{
    padding: 0px;
}

.sitemap__footer{
    background-color: #323c49;
    color: #ffffff;
    text-align: center;
}

.sitemap__footer a{
    color: #ffffff;
    transition: 0.4s;
    text-decoration: none;
}

.sitemap__footer a:hover{
    /* color: #ffce01; */
    color: rgb(113,226,179);
}

/* .navbar{
    box-shadow: 0px 2px #cccccc;   
} */
.menu-large {
    position: static !important;
  }
  
  .megamenu {
    padding: 20px 20px;
    width: 100%;
  }
  
  .megamenu > div > li > ul {
    padding: 0;
    margin: 0;
  }
  
  .megamenu > div > li > ul > li {
    list-style: none;
  }
  
  .megamenu > div > li > ul > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: normal;
  }
  
  .megamenu > div > li > ul > li > a:hover,
  .megamenu > div > li > ul > li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
  }
  
  .megamenu.disabled > a,
  .megamenu.disabled > a:hover,
  .megamenu.disabled > a:focus {
    color: #999999;
  }
  
  .megamenu.disabled > a:hover,
  .megamenu.disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid: DXImageTransform.Microsoft.gradient(enabled=false);
    cursor: not-allowed;
  }
  
  .megamenu.dropdown-header {
    color: #428bca;
    font-size: 18px;
  }
  
  @media (max-width: 768px) {
    .megamenu {
      margin-left: 0;
      margin-right: 0;
    }
    .megamenu > li {
      margin-bottom: 30px;
    }
    .megamenu > li:last-child {
      margin-bottom: 0;
    }
    .megamenu.dropdown-header {
      padding: 3px 15px !important;
    }
    .navbar-nav .open .dropdown-menu .dropdown-header {
      color: #fff;
    }
  }
  
  
.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	transition-delay: 1.5s;
}

.slide.animateOut h1 {
	transition-delay: .3s;
}

.slide.animateOut p {
	transition-delay: .2s;
}

.slide.animateOut section span {
	transition-delay: .1s;
}

.slide.animateOut section span strong {
	transition-delay: 0s;
}
button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  /* background: #FFD800; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);  
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
  align-items: center;
}

button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
  max-height: 100vh;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.95));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		z-index: 10;
    background: url('/assets/icon.png') no-repeat center center / 30px;
		width: 40px;
		height: 40px;
		text-indent: -9999px;
		cursor: pointer;
}

/* .previousButton:hover, .nextButton:hover {
  background: url('https://www.flaticon.com/svg/static/icons/svg/570/570220.svg') no-repeat center center / 16px;
} */

.previousButton {
  left: 0;
  /* -webkit-transform: rotate(360deg) translateY(calc(50% + 0px));
          transform: rotate(360deg) translateY(calc(50% + 0px)); */
}

/* .previousButton:hover {
  left: -10px;
} */

.nextButton {
  right: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

/* .nextButton:hover {
  right: -10px;
} */

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 1.5;
  margin: 10px auto 10px;
  /* max-width: 640px; */
  display: block;
}

.slider-content .inner a {
  color: #FFFFFF;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }    
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
    list-style: 40px;
  }    
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
*{
    margin: 0;
    padding: 0;
}

body {
    margin: 0px;
    padding: 0px;
}

.home__carousel button{
    padding: 0px;
}

/* section{
    margin-bottom: 30px;
} */

/* a{
    color: #ffce01;
} */
/* 
a:hover{
    color: #ffce01;
} */

/* .home__component{
    margin-top: 30px;
} */

.home__component section {
    margin-top: 30px;
    margin-bottom: 30px;
}

.abroad__title {
    padding-left: 50px;
    padding-right: 50px;
}
.home__component li{
    list-style-type: none;
}

/* .fa-stack{
    color: #ffce01;
} */

.head__title{
    /* color: #ffce01; */
    color: rgb(113,226,179);
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 58px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 15px;
}

.head__desc{
    text-align: center;
    color: #000000;
}

.banner__image{
    width: 100%;
}

.banner__sub_title{
    font-family: 'Open Sans Condensed', sans-serif;
    /* font-size: 30px;
    font-weight: 700; */
    color: black;
}

.banner__bg{
    background-image: url('/assets/images/home-page-banner-2.png');
    /* max-width: 1350px; */
    margin-top: 30px;
    padding: 10px;
    margin-right:auto;
    margin-left:auto;
}

.who__we_are{
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
}

.who__we_are h3{
    text-align: center;
}

.banner__bg .who__we_are > h3 {
    font-family: Open Sans Condensed; 
    font-size: 34px;
    font-weight: 700;
}

.four__col{
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.four__col h3{
    text-align: center;
}

.four__col span{
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    text-align: center;
}
.home__info{
    /* background-color: #003770; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
    /* padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px; */
    padding: 15px;
}

.home__info h3{
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
}

.home__info p{
    text-align: center;
    color: #fff;
    display: block;
}

.info__icon {
    margin-bottom: 5px;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    text-align: center;
}

.study__mbbs{
    margin-top: 30px;
}

.countries__list{
    margin-top: 30px;
}

.countries__info, .countries__flag, .countries__name, .countries__desc {
    text-align: center;
}

.countries__info{
    margin-top: 15px;
    margin-bottom: 15px;
}

.countries__name{
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    color: #262d37;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.countries__flag{
    width: 183px;
    height: 183px;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.3);
    cursor: pointer;
}

.apply__now__btn{
    color: #fff;
    font-weight: 500;
    margin-bottom: 25px;
}

.consultancy__india{
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .head__title{
        font-size: 35px;
        line-height: 30px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .four__col h3{
        text-align: center;
    }
    .banner__sub_title{
        margin-top: 15px;
    }
    .home__info p{
        display: block;
    }
}
.home__carousel{
    align-items: center;
    justify-content: center;
    /* height: 60vh; */
}

.home__carousel p{
    display: block;
    padding: 3px;
    margin-bottom: 0px;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
}

.home__carousel__img{
    width: 150px;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}
.home__carousel__stuname{
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #262d37;
    text-align: center;
    display: block;
}

.home__carousel__uni{
    font-size: 18px;
    font-family: "Open Sans Condensed", sans-serif;
    color: #0367bf;
    font-weight: 500;
    margin-bottom: 3px;
    text-align: center;
    display: block;
}

.home__carousel__info{
    font-size: 15px;
    font-weight: 400;
    font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
    font-style: italic;
    color: #000;
    text-align: center;
    display: block;
}
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700'); */

* {
  scroll-behavior: smooth !important;
}

a{
  /* color: #ffce01; */
  color: rgb(113,226,179);
  /* color: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%); */
}

a:hover{
  color: rgb(113,226,179);
}

.fa-stack{
  color: rgb(113,226,179);
  /* background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%); */
}

.service__title {
  color: #000000;
  font-size: 46px;
  line-height: 56px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
}

.service__sub__title {
  font-family: "Open Sans Condensed";
  font-size: 34px;
  line-height: 44px;
  font-weight: 700;
  color: #000000;
}

#index_sec, #about_sec, #why_mbbs_sec, #benefits_sec, #university_sec, #university_sec, #requirements_sec, #fees_sec, #advice_sec, #pros_sec, #advantage_sec, #disadvantage_sec, #visa_sec, #mci_sec, #usmle_sec, #usmle_steps_sec, #pg_sec, #vacations_sec, #career_sec, #problems_sec, #reserve_sec, #charges_sec, #destinex_sec, #process_sec, #faq_sec, #testimonials, #info_sec, #university__videos, #courses_sec, #why_medical_pg, #pg1_sec, #pg2_sec, #designations_sec, #qualification_sec, #pg3_sec, #pg4_sec, #pg5_sec, #pg6_sec, #pg7_sec, #example_sec, #table_sec, #table1_sec, #conclusion_sec {
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Ukranie Page CSS Begins*/

.college__name {
  padding-left: 1.3em;
}

#bogomolets_univ, #odessa_univ, #blaksea_univ, #donetsk_univ_mariupol, #donetsk_univ_kirovograd, #donetsk_univ_chemigiv,
#vinnytsia_univ, #ivano_univ, #sumy_univ, #zaporozhye_univ, #kyiv_univ, #uzhgorod_univ, #poltava_univ, #ternopil_univ,
#lviv_univ, #bukovinian_univ, #kharkov_univ, #karazin_univ, #dnipropetrovsk_univ {
  margin-top: 30px;
  margin-bottom: 30px;
}

.service__table{
  width: 100% auto;
}

.service__table thead {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #000000;
  font-weight: 600;
  font-size: 17px;
  font-family: Open Sans;
  line-height: 28px;
}

.service__table td {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
}

.service__col__title {
  font-family: "Open Sans Condensed";
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  padding: 0px;
}

/* Ukraine Page CSS Ends */





/* Nursing Job Page CSS Begins */

#types_sec, #apply_sec, #getjob_sec, #job_sec, #vacancy_sec, #salary_sec{
  margin-top: 30px;
  margin-bottom: 30px;
}


/* Nursing Job Page CSS Ends */


.service__bg {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  padding-top: 2px;
  padding-bottom: 2px;
}

.service__image {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.service__info {
  font-size: 16px;
  color: #000000;
  font-family: Open Sans;
  line-height: 28px;
  padding: 0px;
}

.service__info li {
  /* font-size: 16px;
  color: #000000;
  font-family: Open Sans;
  line-height: 28px;
  list-style-position: inside; */
  margin-left: 20px;
}

.hyperlink li {
  list-style-type: none;
  padding: 8px 1px 1px 1px;
  font-size: 16px;
  color: #000000;
  font-family: Open Sans;
}

.hyperlink a {
  /* font-size: 16px; */
  text-decoration: none;
  color: #000000;
  /* font-family: Open Sans; */
  line-height: 28px;
}

.hyperlink img {
  width: 17px;
  -webkit-transform: translateY(-10%) rotate(-180deg);
          transform: translateY(-10%) rotate(-180deg);
}

.popup__btn {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
  border: 0px;
  padding: 20px;
}

.popup__btn:hover {
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #fff;
  font-weight: 600;
  border-radius: 30px;
  border: 0px;
  padding: 20px;
}

.accordion__title {
  font-size: 16px;
  color: #000000;
  font-family: Open Sans;
  line-height: 28px;
  cursor: pointer;
}

/* Youtube Embed Video CSS */

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Contact Page CSS Begins */

.india__map{
  width: 100%;
}

.contact {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.contact__form{
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact__title {
  font-size: 22px;
  line-height: 28px;
  /* color: #ffce01; */
  color: rgb(113,226,179);
  /* background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%); */
  font-weight: 700;
  font-weight: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
  text-transform: uppercase;
}

.contact__info {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #262d37;
  font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
}

.contact__info a {
  color: #262d37;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  /* margin-left: -5px; */
}

.list-inline i{
    color: #fff;
}

.list-inline > li {
    display: inline-block;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 0.2em;
    /* background-color: #ffce01; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
    border-radius: 50%;
    font-size: 20px;
  
}

.con__sub__btn {
    /* background-color: #ffce01; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
    color: #ffffff;
    border-radius: 16px;
    border: 0px;
    font-weight: 500;
}

.con__sub__btn:hover {
    /* background-color: #ffce01; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
    color: #ffffff;
    border-radius: 16px;
    border: 0px;
    font-weight: 500;
}

.cont_map{
    margin-bottom: 15px;
}

.contact__address{
  font-size: 16px;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 5px;
  margin-bottom: 0px;
}

.contact__number{
  font-size: 16px;
  padding: 5px;
}

.contact__number a{
  color: #000000;
}

.contact__page__btn{
  border-radius: 25px;
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #000000;
  border: 0px;
  font-weight: 600;
}

.contact__page__btn:hover{
  /* background-color: #ffce01; */
  background: rgb(113,226,179);
  background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);  
  color: #000000;
}

.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    border: 5px solid #71e2b3;
    margin-top: 15px;
}

.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

/* Contact Page CSS Ends */

.popup__info h2{
    font-size: 30px;
    color: #000000;
    font-weight: 700;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
}

.popup__info p{
    padding: 0px;
}

.asterik__sym{
    color: red;
}
.landing__page__body section{
    margin-bottom: 25px;
}


.landing__page{
    background-color: #fdd42c;
    /* padding: 25px 0px; */
    padding-top: 25px;
}

.landing__page__title{
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 35px;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.landing__page__title_1{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 40px;
    color: #000000;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.landing__page__title_2{
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 45px;
    color: #000000;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
}

.landing__page__title_3{
    font-family: 'Marko One', serif;
    font-weight: 600;
    font-size: 35px;
    color: #000000;
    text-align: center;
}

.landing__page__title_4{
    font-family: 'Mukta', sans-serif;
    font-size: 25px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    text-transform: uppercase;
}

.doctor__image{
    width: 450px;
}

.form__head__img{
    margin-bottom: 5px;
}


.form__bg{
    background-color: #ffffff;
    border-radius: 4px;
    padding: 15px;
}

.landing__page__desc{
    font-family: 'Mukta', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    text-align: center;
}

.landing__page__desc_1{
    font-family: 'Mukta', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}


.landing__page__sec{
    margin: 25px 25px;
}

.landing__page__box{
    transition: box-shadow .3s;
    border-radius: 4px;
    padding: 15px;

}

.landing__page__box:hover{
    border-radius: 4px; 
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.landing__page__box__image{
    width: 20%;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.landing__page__box__desc{
    font-style: italic;
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    display: block;
    text-align: center;
}

.lp_college__logo{
    width: 30%;
    border-radius: 50%;
    border: 2px solid #cccccc;
    float: left;
    margin-right: 10px;
}

.lp__college__name{
    font-family: 'Mukta', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    padding-top: 1.5rem;
}

.lp__college__list{
    overflow-y: scroll;
    /* position: absolute; */
    height: 28.1rem;
}

.lp__college__list ul{
    padding-left: 25px;
}

.lp__college__list__russia{
    overflow-y: scroll;
    /* position: absolute; */
    height: 15rem;
}

.lp__college__list__russia ul{
    padding-left: 25px;
}

.mci__logo{
    width: 100%;
}

.lp__country__sec{
    transition: box-shadow .3s;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 0 5px rgba(33,33,33,.2); 
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
}

.lp__country__sec:hover{
    border-radius: 4px; 
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

.lp__country__img{
    width:50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #cccccc;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.lp__country__name {
    font-family: 'Mukta', sans-serif;
    font-size: 25px;
    font-weight: 700;
    padding-top: 8px;
    color: #000000;
    text-align: center;
}

.free__consultation{
    background-color: #fdd42c;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.excellence__img{
    width: 10%;
}

.free__cons__desc{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
}

.free__cons__btn{
    background-color: red;
    color: #ffffff;
    width: 80%;
    font-weight: 700;
    border-radius: 4px;
}

.lp__contact__no a{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 15px;
}

.lp__social__links li{
    list-style-type: none;
    display: inline-block;
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 30px;
    border-radius: 100%;
    width: 42px;
    height: 42px;
}

.lp__social__links a{
    color: #000;
}

.lp__social__links li:hover{
    background-color: #fdd42c;
    color: #ffffff;
    transition: 0.4s;
}


.lp__testimonials p{
    padding: 3px;
    margin-bottom: 0px;
}

.lp__testimonials button{
    padding: 0px;
}


.ism__logo{
    width: 100%;
    vertical-align: middle;
}

@media screen and (max-width: 768px) {

    .landing__page__body section{
        margin-bottom: 25px;
    }

    .landing__page__title{
        margin-top: 15px;
        
    }

    .landing__page__logo{
        display: block;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .arrow__image {
      display: none;
    }

    .doctor__image{
        width: 100%;
        padding-bottom: 15px;
    }
    
    .form__bg{
        margin-bottom: 2rem;
    }

    .landing__page__desc{
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .landing__page__title_3{
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .landing__page__sec{
        width: 80%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
    }

    .landing__page__box__desc{
        font-size: 25px;
    }

    .landing__page__box{
        width: 100%;
        display: inline;
    }

    .lp_college__logo{
        max-width: 4rem;
    }

    .lp__college__name{
        padding-top: 0rem;
        vertical-align: middle;
    }

    .excellence__img{
        width: 80%;
    }
}

@media screen and (min-width: 769px) and (max-width: 968px) {
    .arrow__image {
        display: none;
    }

    .form__head__img{
        width: 90%;
    }

    .landing__page__box__desc{
        font-size: 20px;
        display:inline-flexbox;
        text-align: center;
		}
		
		.lp__country__name{
			font-size: 18px;
			/* padding-right: 15px; */
		}
		.lp__country__sec{
			width: 113px;
			margin-left: 5px;
		}
}

  
/* Title Background CSS Begins */

.service__bg{
    background-color: #ffce01;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    padding: 5px;
}

.service__title{
    color: #000000;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
}

/* Title Background CSS Ends */

.blog__posts link{
    color: #000000;
    text-decoration: none;
}


.blog-card {
    position: relative;
    box-shadow: 0 2px 7px #dfdfdf;
    margin: 15px auto;
    background: #dfd8d8;
    overflow: hidden;
}

.blog-card a{
    color: #000000;
    text-decoration: none;
}

.blog-card a:hover{
    color: #ffce01;
    transition: 0.4s;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    background: #404040;
    color: #fff;
    padding: 3px 10px;
}

.blog-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-tumb img {
    max-width: 100%;
    max-height: 100%;
}

.blog-details {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.blog-details h4 {
    font-weight: 500;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
    transition: 0.3s;
}

/* .blog-details h4 {
    opacity: 0.7;
} */

.blog-author p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #000000;
    transition: 0.3s;
    cursor: pointer;
}

.blog-author p:hover {
    opacity: 0.7;
}

.blog-author img{
    border-radius: 50%;
    max-width: 35%;
    max-height: 35%;
    padding: 15px;
}


/* Single Blog Post CSS Begins*/

.blog__post__img img{
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/* .blog__post p{
    padding: 0px;
} */

.blog__post li{
    list-style-position: inside;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
}

.blog__post__desc{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.blog__post__desc li{
    list-style-position: inside;
}

.blog__post__title {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #4054b2;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.blog__post__list li{
    list-style-type: none;
    margin-left: 10px;
    line-height: 25px;
}

.blog__post__list img{
    width: 20px;
    height: 20px;
    /* vertical-align: top; */
}

.blog__post__aut_box{
    background-color: #e2e3e4;
    color: #000000;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog__post__aut_img{
    border-radius: 50%;
    /* float: left; */
    width: 100px;
    height: 100px;
    margin-left: 10px;
}

.blog__post__aut_info{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.blog__post__table thead{
    /* background-color: #ffce01; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  font-family: Open Sans;
  line-height: 28px;
}

.blog__post__table td{
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 28px;
}

.blog__post__col_f__image img{
    /* border: 2px solid #cccccc; */
    /* padding: 10px; */
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
    width: 100%;
    background-size: 100% 100%;
}

.blog__post__col_f__image{
    border: 2px solid #cccccc;
    margin-top: 15px;
    margin-bottom: 15px;
    /* width: 100%;
    background-size: 100% 100%; */
}

@media screen and (max-width: 768px) {
    .blog__post__aut_img{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
    .blog__post__aut_info{
        float: none;
    }
}

/* Single Blog Post CSS Ends */
