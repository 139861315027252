*{
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

.logo__text{
    text-align: justify;
    height: 80px;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
}

.logo__text img{
    display: block;
    margin: 0 auto;
    width: 130px;
}

.logo__text p{
    padding: 0px;
}

/* .nav__logo{
    width: 180px;
    height: 50px;
} */

.navbar-toggler{
    border: 1px solid;
    background-color: #ccc;
}

.styles_scroll-to-top__2A70v{
    padding: 7px 7px;
    left: 40px !important;
    bottom: 40px !important;
}

.nav-link {
    color: #000000 !important;
    font-family: Roboto, Helvetica, Arial, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 700;
}


.dropdown-item{
    color: #000000;
    font-size: 16px;
}

.country__flag {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #ccc;
    float: left;
    margin-right: 5px;
    margin-top: -10px;
}

.app{
    overflow: hidden;
}

.navbar__list {
    display: inline-grid;
    grid-template-columns: repeat(3, 3fr);
    grid-auto-rows: 70px;
    min-width: 44rem;
    list-style-type: none;
    padding: 10px;
}

.navbar__list li a{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.dropdown-menu.show{
    left: -21rem;
}

.mbbs__abroad p{
    display: block;
    padding-top: 7px;
    padding-right: 2px;
}

.gallery__grid{
    margin-top: 20px;
    margin-bottom: 20px;
}

.gallery__grid button{
    padding: 0px; 
}

@media(max-width: 768px) {
    .navbar__list {
        grid-template-columns: repeat(1, 1fr);
        min-width: 10rem;
    }
}

@media screen and (min-width: 968px) and (max-width: 1024px){

    .dropdown-menu.show{
        left: -22rem;
    }
}

