/* Title Background CSS Begins */

.service__bg{
    background-color: #ffce01;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    padding: 5px;
}

.service__title{
    color: #000000;
    font-size: 46px;
    line-height: 56px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
}

/* Title Background CSS Ends */

.blog__posts link{
    color: #000000;
    text-decoration: none;
}


.blog-card {
    position: relative;
    box-shadow: 0 2px 7px #dfdfdf;
    margin: 15px auto;
    background: #dfd8d8;
    overflow: hidden;
}

.blog-card a{
    color: #000000;
    text-decoration: none;
}

.blog-card a:hover{
    color: #ffce01;
    transition: 0.4s;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    background: #404040;
    color: #fff;
    padding: 3px 10px;
}

.blog-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-tumb img {
    max-width: 100%;
    max-height: 100%;
}

.blog-details {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.blog-details h4 {
    font-weight: 500;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    color: #000000;
    text-decoration: none;
    transition: 0.3s;
}

/* .blog-details h4 {
    opacity: 0.7;
} */

.blog-author p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #000000;
    transition: 0.3s;
    cursor: pointer;
}

.blog-author p:hover {
    opacity: 0.7;
}

.blog-author img{
    border-radius: 50%;
    max-width: 35%;
    max-height: 35%;
    padding: 15px;
}


/* Single Blog Post CSS Begins*/

.blog__post__img img{
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

/* .blog__post p{
    padding: 0px;
} */

.blog__post li{
    list-style-position: inside;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
}

.blog__post__desc{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.blog__post__desc li{
    list-style-position: inside;
}

.blog__post__title {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #4054b2;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
}

.blog__post__list li{
    list-style-type: none;
    margin-left: 10px;
    line-height: 25px;
}

.blog__post__list img{
    width: 20px;
    height: 20px;
    /* vertical-align: top; */
}

.blog__post__aut_box{
    background-color: #e2e3e4;
    color: #000000;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog__post__aut_img{
    border-radius: 50%;
    /* float: left; */
    width: 100px;
    height: 100px;
    margin-left: 10px;
}

.blog__post__aut_info{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
}

.blog__post__table thead{
    /* background-color: #ffce01; */
    background: rgb(113,226,179);
    background: linear-gradient(90deg, rgba(113,226,179,1) 0%, rgba(88,209,195,1) 35%, rgba(72,188,209,1) 100%);
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  font-family: Open Sans;
  line-height: 28px;
}

.blog__post__table td{
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 28px;
}

.blog__post__col_f__image img{
    /* border: 2px solid #cccccc; */
    /* padding: 10px; */
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
    width: 100%;
    background-size: 100% 100%;
}

.blog__post__col_f__image{
    border: 2px solid #cccccc;
    margin-top: 15px;
    margin-bottom: 15px;
    /* width: 100%;
    background-size: 100% 100%; */
}

@media screen and (max-width: 768px) {
    .blog__post__aut_img{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
    .blog__post__aut_info{
        float: none;
    }
}

/* Single Blog Post CSS Ends */