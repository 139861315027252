.home__carousel{
    align-items: center;
    justify-content: center;
    /* height: 60vh; */
}

.home__carousel p{
    display: block;
    padding: 3px;
    margin-bottom: 0px;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 0px violet;
}

.home__carousel__img{
    width: 150px;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}
.home__carousel__stuname{
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #262d37;
    text-align: center;
    display: block;
}

.home__carousel__uni{
    font-size: 18px;
    font-family: "Open Sans Condensed", sans-serif;
    color: #0367bf;
    font-weight: 500;
    margin-bottom: 3px;
    text-align: center;
    display: block;
}

.home__carousel__info{
    font-size: 15px;
    font-weight: 400;
    font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;
    font-style: italic;
    color: #000;
    text-align: center;
    display: block;
}