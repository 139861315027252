.footer{
    background-color: #003770;
    color: #ffffff;
    padding:25px;
}

.footer a{
    color: #ffffff;
}

.footer a:hover{
    /* color: #ffce01; */
    color: rgb(113,226,179);
    text-decoration: none;
    transition: 0.4s;
}

.footer h3{
    color: #ffffff;
    font-weight: 700;
    font-family: "Open Sans Condensed", Helvetica, Arial, Verdana, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

.footer button{
    color: #ffffff;
    font-weight: 600;
}

.footer__links li{
    list-style-type: none;
    color: #ffffff;
}

.footer p{
    padding: 0px;
}

.sitemap__footer{
    background-color: #323c49;
    color: #ffffff;
    text-align: center;
}

.sitemap__footer a{
    color: #ffffff;
    transition: 0.4s;
    text-decoration: none;
}

.sitemap__footer a:hover{
    /* color: #ffce01; */
    color: rgb(113,226,179);
}
